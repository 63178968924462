import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../common/layout"
import Seo from "../common/seo"

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap"

import "bootstrap/dist/css/bootstrap.min.css"

import "tachyons"

const titleStyle = {
  fontSize: "1.2em",
}

const descStyle = {
  fontStyle: "italic",
}

const Price = () => {
  const priceList = [
    {
      title: "Zwangerschapsyoga",
      description: "4 lessen, inclusief map en lesmateriaal",
      price: "€ 58,00",
    },
    {
      title: "Yoga na de bevalling",
      description: "3 lessen, inclusief werkboek",
      price: "€ 58,00",
    },
    {
      title: "Raja Yoga",
      price: "Tienrittenkaart € 140,00, losse les € 15,00",
    },
    {
      title: "Yoga op maat",
      price: "Op aanvraag",
    },
    {
      title: "Babymassage",
      description: "3 lessen, inclusief olie en werkboek",
      price: "€ 65,00",
    },
    {
      title: "Zwangerschaps- en Ayurvedische massage",
      description: "De massage duurt ongeveer 90 minuten",
      price: "€ 83,00",
    },
    {
      title: "Voetreflexmassage",
      description: "De massage duurt ongeveer 30 minuten",
      price: "€ 25,00",
    },
    {
      title: "Bedrijfsmassage",
      price: "Op aanvraag",
    },
    {
      title: "Babyvoetreflex",
      description:
        "3 lessen, inclusief werkboek (het geld dient drie dagen voor de cursus begint op rekening van Flex and Relax te staan",
      price: "€ 55,00",
    },
    {
      title: "Begeleiding bij de bevalling - Doula",
      description:
        "Cursisten van de zwangerschapsyoga ontvangen 10% korting. Voor alleenstaande moeders, vraag naar de speciale korting.",
      price: "€ 695,00",
    },
    {
      title: "Reiskosten",
      description:
        "Wil je de massage thuis ontvangen? Dan zijn daar extra kosten aan verbonden van € 10,- binnen Den Helder. Buiten Den Helder komt daar 0,50 cent per gereden kilometer van deur tot deur bij.",
    },
  ]

  return (
    <Layout>
      <Seo title="Prijzen Flex and Relax" description="Wat zijn de kosten?" />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="bg-washed-red pv5 flex flex-column items-center">
        <h1 className="db f1 display fw1">Prijzen</h1>
        <p className="db sans-serif f5 tracked ttu tc">
          Ingangsdatum: 1 september, 2022
        </p>
      </div>
      <div className="lh-copy f4 serif mw8 center">
        <Container className="pv5">
          <ListGroup>
            {priceList.map((item, index) => (
              <ListGroupItem key={index}>
                <Row>
                  <Col md="4">
                    <span style={titleStyle}>{item?.title}</span>
                  </Col>
                  <Col md="6">
                    <span style={descStyle}>{item?.description}</span>
                  </Col>
                  <Col md="2">{item?.price}</Col>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Container>
      </div>
    </Layout>
  )
}

export default Price
